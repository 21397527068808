.hide{
	display:none!important
}


body {
	overflow: hidden;
	padding: 0;

	margin: 0;
 	font-family: "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", sans-serif;

	
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.malgun { 
	font-family:'Malgun Gothic' !important; 
}
/*
sik 수정

*/


#kakao-link-btn img { 
	
	width: 38px; 
	height: 38px;
	
}

.spin_center {
   width: 100%;
   height: 100%;
   position: absolute;
   left: 45%;
   top: 50%; 
}

/*
input[type="text"], textarea {
	outline: none;
	box-shadow:none !important;
	border:0px solid #ccc !important;
}
*/

.CustomOverlay {
	
	position:absolute;
	top:-52px;
	left:55px;
	
	/*
	max-width:287.5px;
	*/
	max-width:290px;
	min-width: 190px;
	
	float:left;
	
	background-color:transparent;
	border:none;
	
	display:"flex";
	flex-direction:column;
	
}



.CustomOverlay:nth-of-type(n) {
	border:0; 
	/*
	box-shadow:0px 1px 2px #888;
	*/
}

.CustomOverlay .CustomOverlayTitle {
	
	min-height:25px;
	border-radius: 6px 6px 0 0; 
	
	/*
	display:block;
	*/
	flex-shrink:0;
	
	display:flex;
	justify-content:center;
	align-items:center;
	
	overflow:hidden;
	
	background:#167F39;
	color:white;
	text-decoration:none;
	font-weight:bold;
	text-align:center;
	
}


.CustomOverlay .CustomOverlayTitleForKinder {

	min-height:25px;
	border-radius: 6px 6px 0 0; 
	
	/*
	display:block;
	*/
	flex-shrink:0;
	
	display:flex;
	justify-content:center;
	align-items:center;
	overflow:hidden;
	
	background:MediumBlue;
	color:white;
	text-decoration:none;
	font-weight:bold;
	text-align:center;
	
}


.CustomOverlay .CustomOverlayContent {
	
	display:block;
	text-align:left;
	
	flex-grow:1;
	
	/*
	max-width:287.5px;
	*/
	max-width:290px;
	min-width: 190px; 
	
	max-height: 225px;
	
	padding-left:5px;
	border-radius: 0 0 6px 6px;
	
	font-size:90%;
	
	background: #fff;
	
	/*
	background: #fff url(http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;
	*/
}

/*
.CustomOverlay .CustomOverlayHeader {
	
	position:absolute;
	
	top:-8%;
	height:10%;
	left:10px;
	
	transform:translate(8%,-10px);
	
	margin:0 0 0 0;
	padding:0 0 0 0;
	
	color:#167F39;
	background-color:transparent;
	
}


.CustomOverlay .CustomOverlayHeaderForKinder {
	
	position:absolute;
	
	top:-12%;
	height:10%;
	left:10px;
	
	transform:translate(12%,-10px);
	
	margin:0 0 0 0;
	padding:0 0 0 0;
	
	color:MediumBlue;
	background-color:transparent;
	
}
*/


.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
}

.hr-text:before {
	content: '';
	/* 
		use the linear-gradient for the fading effect 
		use a solid background color for a solid bar
		*/
	background: linear-gradient(to right, transparent, #818078, transparent);
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
}
  
.hr-text:after {
	content: attr(data-content);
	position: relative;
	display: inline-block;
	color: black;

	padding: 0 .5em;
	line-height: 1.5em;
	/*
		this is really the only tricky part, you need to specify the background color of the container element...
	*/
	color: #818078;
	background-color: #fcfcfa;
}

.kinder_auth_info_detail_pdf {
	
	width:100%;
	height:100%;
	
}
